import React from "react"
import "./style.css"

export default function VideoContainer({ src }) {
    return (
        <div className="video-container">
            <div className="video-cover" />
            <video loop autoPlay muted id="index-video">
                <source src={src} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    )
}
