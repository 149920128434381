import React from "react"
import "./style.css"

const Frame = ({ children, frame, extraPadding }) => {
    const getClass = () => {
        const classNameArr = [`frame frame_${frame}`]
        if (extraPadding) {
            classNameArr.push("extraPadding")
        }
        return classNameArr.join(" ")
    }

    return <div className={getClass()}>{children}</div>
}

export default Frame
