import React from "react"
import "./style.css"

const AppRow = ({
    title,
    picNumArr,
    description,
    isReversed,
    noMarginBottom
}) => {
    const getClass = () => {
        const classNameArr = ["app-row__container"]
        if (isReversed) {
            classNameArr.push("isReversed")
        }

        if (noMarginBottom) {
            classNameArr.push("noMarginBottom")
        }

        return classNameArr.join(" ")
    }

    return (
        <div className={getClass()}>
            <div className="app-row__content">
                <h3 className="app-row__content-title">{title}</h3>
                <p className="app-row__content-description">{description}</p>
            </div>
            <div className="app-row__images">
                {picNumArr.map(picNum => {
                    const imageSrc = `./MobileApp/mobile${picNum}.png`
                    return (
                        <img
                            key={picNum}
                            src={imageSrc}
                            alt="mobile-app"
                            className="app-row__images--app"
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default AppRow
