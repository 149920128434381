import React from "react"
import TopSection from "../../components/TopSection"
import Button from "../../components/Button"
// import HeaderImage from "../../images/HeaderImage.png"
import AppDemo from "../../images/AppDemo.png"
import { externalUrls } from "../../utils/social"
import appleStoreButton from "../../images/stores/apple_store.png"
import playStoreButton from "../../images/stores/play_store.png"

import "./style.css"

const Signup = ({ imgUrl = undefined }) => {
    return (
        <TopSection image={imgUrl || AppDemo} imageWidth="27rem">
            <div className="signup-section__left">
                <h1>#1 platform for female golfers.</h1>
                <p className="signup-section__left__description">
                    A community-based social network that lets all female
                    golfers connect, develop their skills and explore unique
                    golf related experiences, golf courses and brands globally.
                </p>
                <p className="signup-section__left__description">
                    Get inspired, expand your network and golf in a new way!
                </p>
                <p className="signup-section__left__description bold">
                    Welcome to the world of ARAY!
                </p>

                <div className="store-container">
                    <a href={externalUrls.appleStore}>
                        <img
                            alt="img"
                            src={appleStoreButton}
                            className="store-apple__image"
                        />
                    </a>

                    <a href={externalUrls.playStore}>
                        <img
                            alt="img"
                            src={playStoreButton}
                            className="store-play__image"
                        />
                    </a>
                </div>
                {/* <a href={externalUrls.signup}>
                    <Button className="signup-section__signup-btn">
                        SIGN UP
                    </Button>
                </a> */}
            </div>
        </TopSection>
    )
}

export default Signup
