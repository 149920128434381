import React from "react"
import Frame from "../../components/Frame"
import VideoContainer from "../VideoContainer"
import "./style.css"
import videoSrc from "../../../static/video_aboutus.mp4"

const TopSection = ({ children, image, imageWidth, hasVideo }) => {
    return (
        <Frame frame={1}>
            <div className="top-section">
                <div className="top-section__left">{children}</div>
                {!!image && (
                    <div className="top-section__right">
                        <img
                            alt="img"
                            src={image}
                            className="top-section__image"
                            style={imageWidth && { width: imageWidth }}
                        />
                    </div>
                )}
                {/* REFACTOR: make hasVideo available for index page section */}
                {hasVideo && <VideoContainer src={videoSrc} />}
            </div>
        </Frame>
    )
}

export default TopSection
