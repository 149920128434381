import React from "react"
import "./style.css"

const Button = ({ children, secondary = false, className, onClick }) => {
    return (
        <button
            className={`button-container ${
                secondary && "isSecondary"
            } ${className}`}
            onClick={onClick}
        >
            {children}
        </button>
    )
}

export default Button
